import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

import { LoadingButton } from '@mui/lab';
import { Box, colors, Typography } from '@mui/material';

import { api, apiAWS } from 'services/api';
import { setAccountType, signInAsync } from 'store/modules/auth/actions';

import poweredByImage from 'assets/svg/powered-by-investflex-mono.svg';

export const SignOnForm = ({
	userInfo,
	isMobile,
	isTokenValid,
	setAuthToken,
	setSignOnStep,
	setContractInfo,
	setSnackbarState,
}) => {
	const dispatch = useDispatch();
	const history = useHistory();

	const [buttonsLoading, setButtonsLoading] = useState({
		demo: false,
		real: false,
	});

	function verifyEnvironment(environmentType) {
		if (environmentType === 'real') {
			return process.env.REACT_APP_USR_DMA_API_URL;
		}

		if (environmentType === 'demo') {
			return process.env.REACT_APP_USR_SIM_API_URL;
		}

		setSnackbarState(state => ({
			...state,
			isOpen: true,
			severity: 'error',
			message: 'Erro ao encontrar o ambiente selecionado.',
		}));
	}

	function checkHaveContractPendingIssues(token, environmentType) {
		const newUserName = userInfo.user_name.toLowerCase();
		const headers = { headers: { Authorization: `Bearer ${token}` } };

		apiAWS
			.get(`/users/${newUserName}/contracts`, headers)
			.then(response => {
				const contracts = response.data.records;

				if (contracts.length > 0) {
					const contract = contracts[0];

					if (contract.have_pendencies) {
						setContractInfo(contract);
						setSignOnStep('contract');
						setButtonsLoading({
							demo: false,
							real: false,
						});
					} else {
						dispatch(
							signInAsync({
								authToken: token,
								userName: userInfo.user_name,
								history,
								accountType: environmentType,
								userIp: contract.user_ip,
								userDate: contract.datetime,
								productPath: contract.product,
								handleCancelContractSign: () =>
									setSignOnStep('sign-on-form'),
							})
						);
					}
				} else {
					setSignOnStep('sign-on-form');
					setButtonsLoading({
						demo: false,
						real: false,
					});

					setSnackbarState(state => ({
						...state,
						isOpen: true,
						severity: 'error',
						message:
							'Não foi encontrado nenhum contrato vinculado ao seu usuário.',
					}));
				}
			})
			.catch(error => console.error(error));
	}

	function handleSignOn(environmentType) {
		const environment = verifyEnvironment(environmentType);

		if (environment) {
			setButtonsLoading({
				demo: environmentType === 'demo',
				real: environmentType === 'real',
			});

			const configs = {
				headers: {
					'content-type': 'application/json',
					Authorization: `Bearer ${userInfo.transactional_token}`,
					'token-login-mode': true,
				},
				timeout: 15000,
			};

			api.post(`${environment}/connect/login`, {}, configs)
				.then(response => {
					const decodedAuthToken = jwtDecode(
						response.data.auth_token
					);

					if (decodedAuthToken.prd.length > 0) {
						dispatch(setAccountType(environmentType));
						setAuthToken(response.data.auth_token);

						checkHaveContractPendingIssues(
							response.data.auth_token,
							environmentType
						);
					} else {
						setSnackbarState(state => ({
							...state,
							isOpen: true,
							severity: 'error',
							message:
								'Não foi encontrado nenhum produto vinculado ao seu usuário.',
						}));
						setButtonsLoading({
							demo: false,
							real: false,
						});
					}
				})
				.catch(error => {
					console.error(error);
					setButtonsLoading({
						demo: false,
						real: false,
					});
					setSnackbarState(state => ({
						...state,
						isOpen: true,
						severity: 'error',
						message: 'Erro ao realizar login.',
					}));
				});
		}
	}

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				gap: 2,
				py: isMobile ? 0 : 4,

				'& >img': {
					width: '50%',
					mt: 4,
				},
			}}
		>
			<Typography
				fontWeight="bold"
				variant="h5"
				textAlign="center"
				sx={{ color: colors.grey[700], mb: 1 }}
			>
				Em qual ambiente você deseja entrar?
			</Typography>

			<LoadingButton
				disabled={
					buttonsLoading.real || buttonsLoading.demo || !isTokenValid
				}
				size="large"
				variant="contained"
				color="warning"
				fullWidth
				loading={buttonsLoading.demo}
				onClick={() => handleSignOn('demo')}
			>
				Ambiente simulado
			</LoadingButton>

			<LoadingButton
				disabled={
					buttonsLoading.real || buttonsLoading.demo || !isTokenValid
				}
				size="large"
				variant="contained"
				color="success"
				fullWidth
				loading={buttonsLoading.real}
				onClick={() => handleSignOn('real')}
			>
				Ambiente real
			</LoadingButton>

			<img src={poweredByImage} alt="" />
		</Box>
	);
};
