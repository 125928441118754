import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
	ClickAwayListener,
	Fade,
	List,
	MenuItem,
	Paper,
	Popper,
} from '@mui/material';

import flexScanImage from 'assets/images/flexscan_white_logo.png';
import tradeCertoImage from 'assets/images/trade-certo-branco.png';
import radarImage from 'assets/images/logo_radar.png';
import elliotImage from 'assets/svg/elliot-light.svg';

import {
	EXTERNAL_PRODUCTS,
	PRODUCT_FAMILIES,
	PRODUCT_TYPES,
} from 'utils/constants';
import { changeActiveProduct } from 'utils/changeActiveProduct';

const PRODUCT_IMAGES = {
	[PRODUCT_TYPES.FLEX_PRO]: flexScanImage,
	[PRODUCT_TYPES.FLEX_CUSTOM]: flexScanImage,
	[PRODUCT_TYPES.FLEX_ANALYTIC]: flexScanImage,
	[PRODUCT_TYPES.FLEX_STANDARD]: flexScanImage,
	[PRODUCT_TYPES.FLEX_ADVANCED]: flexScanImage,
	[EXTERNAL_PRODUCTS.TRADE_CERTO]: tradeCertoImage,
	[EXTERNAL_PRODUCTS.TC_STANDARD]: tradeCertoImage,
	[EXTERNAL_PRODUCTS.RADAR_DIV]: radarImage,
	[EXTERNAL_PRODUCTS.RADAR_STANDARD]: radarImage,
	[EXTERNAL_PRODUCTS.RADAR_PRO]: radarImage,
};

export const ProductSelector = () => {
	const history = useHistory();
	const { activeProduct, userSignatures } = useSelector(
		state => state.products
	);
	const sponsor = useSelector(state => state.auth.sponsor);

	const [anchorEl, setAnchorEl] = useState(null);
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	const filteredSignatures = useMemo(
		() =>
			userSignatures.filter(
				signature => signature.product.code !== activeProduct.code
			),
		[userSignatures, activeProduct]
	);

	const handleOpenMenu = useCallback(
		event => {
			if (filteredSignatures.length > 0) {
				setAnchorEl(event.currentTarget);
				setIsMenuOpen(true);
			}
		},
		[filteredSignatures]
	);

	const handleCloseMenu = useCallback(() => {
		setAnchorEl(null);
		setIsMenuOpen(false);
	}, []);

	function getProductImage(product) {
		if (sponsor === 'eli') {
			if (product.family === PRODUCT_FAMILIES.FLEXSCAN) {
				return elliotImage;
			}
		}

		return PRODUCT_IMAGES[product.code];
	}

	const activeProductImage = useMemo(
		() => getProductImage(activeProduct),
		[activeProduct]
	);

	return (
		<>
			<img
				src={activeProductImage}
				alt={activeProduct.name}
				onClick={handleOpenMenu}
				style={{
					cursor:
						filteredSignatures.length === 0 ? 'default' : 'pointer',
				}}
			/>

			<Popper
				open={isMenuOpen}
				anchorEl={anchorEl}
				placement="bottom"
				transition
				disablePortal
			>
				{({ TransitionProps }) => (
					<ClickAwayListener onClickAway={handleCloseMenu}>
						<Fade {...TransitionProps}>
							<Paper
								elevation={12}
								square={false}
								sx={{ mt: 1.5, ml: 2 }}
							>
								<List
									sx={{ py: 0, borderRadius: 1 }}
									aria-label="Seleção de produtos"
								>
									{filteredSignatures.map(signature => {
										const signatureImage = getProductImage(
											signature.product
										);

										return (
											<MenuItem
												key={signature.product.code}
												onClick={() =>
													changeActiveProduct(
														signature,
														history
													)
												}
											>
												<img
													src={signatureImage}
													alt={signature.product.name}
													style={{ width: 100 }}
												/>
											</MenuItem>
										);
									})}
								</List>
							</Paper>
						</Fade>
					</ClickAwayListener>
				)}
			</Popper>
		</>
	);
};
