import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ptBR } from 'date-fns/locale';

import {
	Autocomplete,
	Button,
	FormControl,
	Grid,
	TextField,
} from '@mui/material';
import { LocalizationProvider, DateRangePicker } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { LoadingButton } from '@mui/lab';
import { Search } from '@mui/icons-material';

import {
	clearFormValues,
	updateOrdersFormValues,
} from 'store/modules/executions/actions';

import { PRODUCT_TYPES, USER_TYPES } from 'utils/constants';
import { onSuggestionsFetchRequested } from 'components/MaterialComponents/MaterialBullet/bulletFunctions';
import { getDayPicker } from 'utils/getDayPicker';
import { changeRangeDate } from 'utils/rangeDateHelpers';
import { orderStatus } from 'utils/strategies';

export const ViewExecutionsSearchForm = ({
	isLoading,
	handleSearch,
	groupAccounts,
	setSnackbarState,
	activeProduct,
	isStatusExecution,
}) => {
	const dispatch = useDispatch();

	const { userType } = useSelector(state => state.auth);
	const { searchForm, paginationInfo } = useSelector(
		state => state.executions
	);

	const [suggestions, setSuggestions] = useState([]);

	function handleChangeSearchFormValues(field, value) {
		if (field === 'status') {
			const isNowExecution = value === 'F';
			const today = new Date();

			if (isStatusExecution && !isNowExecution) {
				dispatch(updateOrdersFormValues({ dates: [today, today] }));
			}
		}

		dispatch(updateOrdersFormValues({ [field]: value }));
	}

	function handleChangeDatePicker(rangeDate) {
		changeRangeDate(
			rangeDate,
			'dates',
			updateOrdersFormValues,
			true,
			'2023-11-01',
			'Os dados de execuções só estão disponíveis a partir da data 01/11/2023',
			setSnackbarState
		);
	}

	function handleClear() {
		dispatch(clearFormValues(groupAccounts));
	}

	function onAutoCompleteChange(value, reason) {
		if (reason === 'clear') {
			handleChangeSearchFormValues('active', '');
		} else {
			if (!value) {
				return;
			}

			const symbol = value?.source?.symbol ?? value;

			if (symbol === undefined) {
				return;
			}

			handleChangeSearchFormValues('active', symbol);
		}
	}

	async function onAutoCompleteInputChange(event) {
		const value = event.target.value.toUpperCase();

		const suggestionsResponse = await onSuggestionsFetchRequested(
			value,
			false,
			false,
			0,
			'executions'
		);

		setSuggestions(suggestionsResponse);

		handleChangeSearchFormValues('active', value);
	}

	return (
		<form
			onSubmit={event =>
				handleSearch(
					{
						...paginationInfo,
						page: 0,
						offset: 0,
						total: null,
					},
					searchForm,
					event
				)
			}
			style={{ width: '100%', padding: '0 1rem' }}
		>
			<Grid xs={12} item container spacing={1}>
				<Grid item xs={1.5}>
					{userType.id === USER_TYPES.FINAL_CLIENT ? (
						<Autocomplete
							size="small"
							name="cblc"
							value={searchForm.cblc}
							options={groupAccounts}
							getOptionLabel={option => option}
							renderInput={params => (
								<TextField
									{...params}
									variant="outlined"
									label="CBLC"
									InputLabelProps={{ shrink: true }}
								/>
							)}
							onChange={(_, value) =>
								handleChangeSearchFormValues('cblc', value)
							}
							fullWidth
							filterSelectedOptions
						/>
					) : (
						<TextField
							label="CBLC"
							size="small"
							name="cblc"
							value={searchForm.cblc}
							InputLabelProps={{ shrink: true }}
							onChange={event =>
								handleChangeSearchFormValues(
									event.target.name,
									event.target.value
								)
							}
							fullWidth
						/>
					)}
				</Grid>

				<Grid item xs={2}>
					<TextField
						size="small"
						label="ID da estratégia"
						name="strategyId"
						value={searchForm.strategyId}
						onChange={event =>
							handleChangeSearchFormValues(
								event.target.name,
								event.target.value
							)
						}
						fullWidth
					/>
				</Grid>

				<Grid item xs={1.5}>
					<TextField
						label="Status"
						id="status"
						name="status"
						size="small"
						value={searchForm.status}
						SelectProps={{ native: 'native' }}
						onChange={event =>
							handleChangeSearchFormValues(
								event.target.name,
								event.target.value
							)
						}
						fullWidth
						select
					>
						{orderStatus.map(status => (
							<option key={status.value} value={status.value}>
								{status.label}
							</option>
						))}
					</TextField>
				</Grid>

				<Grid item xs={2}>
					<Autocomplete
						id="active-autocomplete"
						size="small"
						noOptionsText="Informe um instrumento"
						inputValue={searchForm.active}
						renderInput={params => (
							<TextField {...params} label="Instrumento" />
						)}
						options={
							suggestions?.map(option => option.source.symbol) ??
							[]
						}
						onChange={(_, newInstrument, reason) =>
							onAutoCompleteChange(newInstrument, reason)
						}
						onInput={event => onAutoCompleteInputChange(event)}
						disablePortal
						fullWidth
						disabled={!isStatusExecution}
					/>
				</Grid>

				<Grid item xs={2}>
					<FormControl size="small" variant="outlined" fullWidth>
						<LocalizationProvider
							dateAdapter={AdapterDateFns}
							adapterLocale={ptBR}
							localeText={{
								start: 'Data início',
								end: 'Data Fim',
							}}
						>
							<DateRangePicker
								value={searchForm.dates ?? [null, null]}
								onChange={newValue =>
									handleChangeDatePicker(newValue)
								}
								disabled={!isStatusExecution}
								renderInput={(startProps, endProps) => (
									<>
										<TextField
											size="small"
											sx={{ marginRight: '10px' }}
											fullWidth
											{...startProps}
										/>
										<TextField
											size="small"
											fullWidth
											{...endProps}
										/>
									</>
								)}
								maxDate={new Date()}
								dayOfWeekFormatter={day =>
									`${getDayPicker(day)}`
								}
							/>
						</LocalizationProvider>
					</FormControl>
				</Grid>

				<Grid item xs={2}>
					<LoadingButton
						type="submit"
						loading={isLoading}
						variant="contained"
						startIcon={<Search />}
						disabled={
							activeProduct.code === PRODUCT_TYPES.FLEX_ANALYTIC
						}
						sx={{ height: '100%' }}
						fullWidth
					>
						Procurar
					</LoadingButton>
				</Grid>

				<Grid item xs={1}>
					<Button
						variant="contained"
						sx={{
							height: '100%',
							bgcolor: '#fff',
							color: '#2196f3',
							'&:hover': {
								bgcolor: '#e7e7e7',
								color: '#1069b3',
							},
						}}
						onClick={handleClear}
						fullWidth
					>
						Limpar
					</Button>
				</Grid>
			</Grid>
		</form>
	);
};
