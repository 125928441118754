export const getContinousLeg = (leg, context = 'snapshot', ClOrdID = '') => {
	let newLegCont = {};

	// Validação de simbolo
	if (leg.LegSymbol) {
		newLegCont.LegSymbol = leg.LegSymbol;
	}

	// Validação de LegSide (Venda/Compra)
	if (leg.LegSide) {
		if (leg.LegSide === '1') {
			leg.LegExecQty = leg.ExecutedBuyQtty ?? 0;
			leg.LegAvgPx = leg.AvgBuyPrice ?? 0;
			leg.Text = leg.LegBuyMsg ?? '';

			newLegCont.LegSide = '2';
			newLegCont.LegExecQty = leg.ExecutedSellQtty ?? 0;
			newLegCont.LegAvgPx = leg.AvgSellPrice ?? 0;
			newLegCont.Text = leg.LegSellMsg ?? '';
		}

		if (leg.LegSide === '2') {
			leg.LegExecQty = leg.ExecutedSellQtty ?? 0;
			leg.LegAvgPx = leg.AvgSellPrice ?? 0;
			leg.Text = leg.LegSellMsg ?? '';

			newLegCont.LegSide = '1';
			newLegCont.LegExecQty = leg.ExecutedBuyQtty ?? 0;
			newLegCont.LegAvgPx = leg.AvgBuyPrice ?? 0;
			newLegCont.Text = leg.LegBuyMsg ?? '';
		}
	}

	// Validação de conta
	if (leg.ILegAllocAccount) {
		newLegCont.ILegAllocAccount = leg.ILegAllocAccount;
	}

	if (leg.LegRefID) {
		newLegCont.LegRefID = `${leg.LegRefID}:count`;
	}

	if (leg.LegQuantity) {
		newLegCont.LegQuantity = leg.LegQuantity;
	}

	if (leg.LegMaxClipSize) {
		newLegCont.LegMaxClipSize = leg.LegMaxClipSize;
	}

	if (leg.LegCumQtyPerc) {
		newLegCont.LegCumQtyPerc = leg.LegCumQtyPerc;
	}

	if (leg.LegFirstTimeOut) {
		newLegCont.LegFirstTimeOut = leg.LegFirstTimeOut;
	}

	if (leg.LegSecurityExchange) {
		newLegCont.LegSecurityExchange = leg.LegSecurityExchange;
	}

	if (leg.Cst_MinOrderQuantity) {
		newLegCont.Cst_MinOrderQuantity = leg.Cst_MinOrderQuantity;
	}

	if (leg.LegOrdType) {
		newLegCont.LegOrdType = leg.LegOrdType;
	}

	if (leg.LegResting) {
		newLegCont.LegResting = leg.LegResting;
	}

	if (leg.LegSecurityId) {
		newLegCont.LegSecurityId = leg.LegSecurityId;
	}

	if (leg.Cst_MinPriceIncrement) {
		newLegCont.Cst_MinPriceIncrement = leg.Cst_MinPriceIncrement;
	}

	if (['getStrategies', 'history'].includes(context)) {
		/* Realizando o merge da perna original */
		newLegCont = {
			...leg,
			...newLegCont,
		};

		const formattedLegSide =
			newLegCont.LegSide === '1' ? 'Compra' : 'Venda';

		const formattedName = `${newLegCont.LegSymbol} - ${formattedLegSide}`;

		newLegCont.Name = formattedName;

		newLegCont.id = newLegCont.LegRefID;

		newLegCont.hierarchy = [ClOrdID, newLegCont.LegRefID];

		leg.ExecutedQtty = leg.LegExecQty;
		leg.AvgPrice = leg.LegAvgPx;

		newLegCont.ExecutedQtty = newLegCont.LegExecQty;
		newLegCont.AvgPrice = newLegCont.LegAvgPx;
	}

	return newLegCont;
};

export const sortContinousArrayByLegRefID = continousArray => {
	continousArray.sort((a, b) => {
		const aHasCount = /:\d+:count$/.test(a.LegRefID);
		const bHasCount = /:\d+:count$/.test(b.LegRefID);

		/* Se um é original e outro é continou, coloca o original primeiro */
		if (!aHasCount && bHasCount) return -1;
		if (aHasCount && !bHasCount) return 1;

		const aRef = parseInt(a.LegRefID.split(':')[0], 10);
		const bRef = parseInt(b.LegRefID.split(':')[0], 10);

		return aRef - bRef;
	});

	return continousArray;
};

/* Função utilizada para alterar as informações selecionadas da tabela (Linha original) */
export const getFormattedSelectedRowLegs = selectedRow => {
	const rowTable = { ...selectedRow };

	/* Transformando informaçoes importantes, para o tipo numero */
	rowTable.CustomParameters.EntryTriggerValue = Number(
		rowTable.CustomParameters?.EntryTriggerValue
	);

	rowTable.CustomParameters.ExitTriggerValue = Number(
		rowTable.CustomParameters?.ExitTriggerValue
	);

	rowTable.CustomParameters.EntryTriggerType = Number(
		rowTable.CustomParameters?.EntryTriggerType
	);

	rowTable.CustomParameters.ExitTriggerType = Number(
		rowTable.CustomParameters?.ExitTriggerType
	);

	/* Tabela pega os valores originais das legs assim não é trocado mesmo passando pelo strategiesHelpers */
	/* Coletando os valores de quantidade corretamente */
	rowTable.Legs = rowTable.Legs.map(leg => {
		if (leg.LegSide === '1') {
			leg.LegExecQty = Number(leg.ExecutedBuyQtty ?? 0);
			leg.LegAvgPx = Number(leg.AvgBuyPrice ?? 0);
		}

		if (leg.LegSide === '2') {
			leg.LegExecQty = Number(leg.ExecutedSellQtty ?? 0);
			leg.LegAvgPx = Number(leg.AvgSellPrice ?? 0);
		}

		leg.ExecutedQtty = leg.LegExecQty;
		leg.AvgPrice = leg.LegAvgPx;

		return leg;
	});

	return rowTable;
};
