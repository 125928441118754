import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { LoadingButton } from '@mui/lab';
import { Grid, TextField, useMediaQuery, useTheme } from '@mui/material';

import { InstrumentsSearch } from './InstrumentsSearch';
import { MaterialSnackbar } from 'components/MaterialComponents/MaterialSnackbar';

import { apiAWS } from 'services/api';
import { updateHistoricalDataExportForm } from 'store/modules/historicalData/actions';
import { isAfter, isBefore, parse, subDays, subYears } from 'date-fns';

export const HistoricalDataExportForm = () => {
	const dispatch = useDispatch();
	const theme = useTheme();

	const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

	const exportForm = useSelector(state => state.historicalData.exportForm);

	const [isLoading, setIsLoading] = useState(false);
	const [snackbarState, setSnackbarState] = useState({
		isOpen: false,
		message: '',
		severity: 'success',
		autoHide: null,
	});

	function handleChangeExportFormValues(field, value) {
		dispatch(updateHistoricalDataExportForm({ [field]: value }));
	}

	function handleChangeDateFields(field, date) {
		const parsedDate = parse(date, 'yyyy-MM-dd', new Date());

		if (field === 'startDate') {
			const minimumDate = subYears(new Date(), 1);

			if (isBefore(parsedDate, minimumDate)) {
				setSnackbarState(state => ({
					...state,
					isOpen: true,
					message:
						'Data de início não pode ser maior do que 1 ano da data corrente.',
					severity: 'warning',
				}));
				return;
			}
		}

		if (field === 'endDate') {
			const maximumDate = subDays(new Date(), 1);

			if (isAfter(parsedDate, maximumDate)) {
				setSnackbarState(state => ({
					...state,
					isOpen: true,
					message:
						'Data fim não pode ser maior do que 1 dia da data corrente.',
					severity: 'warning',
				}));
				return;
			}
		}

		handleChangeExportFormValues(field, date);
	}

	const timeframeOptions = [
		{ value: 'D', label: '1 dia' },
		{ value: '5m', label: '5 min' },
		{ value: '15m', label: '15 min' },
	];

	const formatOptions = [
		{ value: 1, label: '.csv' },
		{ value: 2, label: '.xlsx' },
		{ value: 3, label: 'Trade Certo (.xlsx)' },
	];

	function handleSubmit() {
		setIsLoading(true);

		const formattedSymbols = exportForm.symbols.map(s => s.label);
		const formattedFormat = Number(exportForm.format);

		const isTradeFormat = formattedFormat === 3;
		const isFiveMinutesTimeFrame = exportForm.timeframe === '5m';

		let message = '';

		if (formattedSymbols.length > 2) {
			if (isTradeFormat) {
				message =
					'Exportar "Trade Certo (.xlsx)" não é possível com mais de 2 instrumentos.';
			} else if (isFiveMinutesTimeFrame) {
				message =
					'Exportar intervalo de "5 min" não é possível com mais de 2 instrumentos.';
			}
		}

		if (message) {
			setSnackbarState(state => ({
				...state,
				isOpen: true,
				message,
				severity: 'warning',
			}));
			setIsLoading(false);
			return;
		}

		const body = {
			symbols: formattedSymbols,
			timeframe: exportForm.timeframe,
			format: formattedFormat,
			startDate: exportForm.startDate,
			endDate: exportForm.endDate,
		};

		apiAWS
			.post('/candles/export', body)
			.then(response => {
				window.open(response.data.url, '_self');
			})
			.catch(error => console.error(error))
			.finally(() => setIsLoading(false));
	}

	return (
		<Grid
			container
			spacing={2}
			direction={isSmallScreen ? 'column' : 'row'}
		>
			<Grid item xs={isSmallScreen ? true : 12}>
				<InstrumentsSearch
					handleChangeExportFormValues={handleChangeExportFormValues}
					isFormLoading={isLoading}
				/>
			</Grid>

			<Grid item xs={isSmallScreen ? true : 2}>
				<TextField
					label="Intervalo"
					id="timeframe"
					name="timeframe"
					size="small"
					disabled={isLoading}
					defaultValue={2}
					value={exportForm.timeframe}
					SelectProps={{ native: 'native' }}
					onChange={event =>
						handleChangeExportFormValues(
							event.target.name,
							event.target.value
						)
					}
					fullWidth
					select
				>
					{timeframeOptions.map(status => (
						<option key={status.value} value={status.value}>
							{status.label}
						</option>
					))}
				</TextField>
			</Grid>

			<Grid item xs={isSmallScreen ? true : 2}>
				<TextField
					label="Formato saída"
					id="format"
					name="format"
					size="small"
					defaultValue={2}
					disabled={isLoading}
					value={exportForm.format}
					SelectProps={{ native: 'native' }}
					onChange={event =>
						handleChangeExportFormValues(
							event.target.name,
							event.target.value
						)
					}
					fullWidth
					select
				>
					{formatOptions.map(status => (
						<option key={status.value} value={status.value}>
							{status.label}
						</option>
					))}
				</TextField>
			</Grid>

			<Grid item xs={isSmallScreen ? true : 3}>
				<TextField
					id="startDate"
					name="startDate"
					fullWidth
					size="small"
					type="date"
					label="Data de início"
					className="black-date-input"
					value={exportForm.startDate}
					InputLabelProps={{ shrink: true }}
					onChange={event =>
						handleChangeDateFields(
							event.target.name,
							event.target.value
						)
					}
				/>
			</Grid>

			<Grid item xs={isSmallScreen ? true : 3}>
				<TextField
					id="endDate"
					name="endDate"
					fullWidth
					size="small"
					type="date"
					label="Data fim"
					className="black-date-input"
					value={exportForm.endDate}
					InputLabelProps={{ shrink: true }}
					onChange={event =>
						handleChangeDateFields(
							event.target.name,
							event.target.value
						)
					}
				/>
			</Grid>

			<Grid item xs={isSmallScreen ? true : 2}>
				<LoadingButton
					type="submit"
					onClick={handleSubmit}
					loading={isLoading}
					disabled={exportForm.symbols.length === 0}
					fullWidth
					variant="contained"
				>
					Exportar
				</LoadingButton>
			</Grid>

			<MaterialSnackbar
				open={snackbarState.isOpen}
				severity={snackbarState.severity}
				autoHide={snackbarState.autoHide}
				handleClose={() =>
					setSnackbarState(state => ({ ...state, isOpen: false }))
				}
			>
				{snackbarState.message}
			</MaterialSnackbar>
		</Grid>
	);
};
