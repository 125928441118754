import { format, isValid, parse } from 'date-fns';

import { legSides } from './strategies';
import { getContinousLeg } from './getContinousLeg';

function getFormattedId(clOrdId, timeStamp) {
	const parsedTimeStamp = parse(timeStamp, 'yyyy-MM-dd HH:mm:ss', new Date());

	if (isValid(parsedTimeStamp)) {
		const formattedTimeStamp = format(parsedTimeStamp, 'yyyy-MM-dd');

		return `${clOrdId}|${formattedTimeStamp}`;
	}

	return clOrdId;
}

export function formatHistoryStrategies(strategies) {
	let formattedStrategies = [];

	strategies.forEach(strategy => {
		const formattedId = getFormattedId(
			strategy.ClOrdID,
			strategy.TimeStamp
		);

		formattedStrategies.push({
			...strategy,
			id: formattedId,
			hierarchy: [formattedId],
			strategyCode: strategy.StrategyCode,
			TimeInForce: strategy.TimeInForce ?? '0',
			Status: strategy.OrdStatus ?? strategy.Status,
		});

		const newLegsCont = [];

		strategy.Legs?.forEach(leg => {
			const formattedLegSide = leg.LegSide === '1' ? 'Compra' : 'Venda';

			const formattedLegId = getFormattedId(
				leg.LegRefID,
				strategy.TimeStamp
			);

			const currentLeg = {
				...leg,
				id: formattedLegId,
				hierarchy: [formattedId, formattedLegId],
				Text: leg.LegMsg,
				CumQtyPerc: leg.LegCumQtyPerc,
				TargetQtty: leg.LegQuantity,
				ExecutedQtty: leg.LegExecQty,
				AvgPrice: leg.LegAvgPx,
				Accounts: leg.ILegAllocAccount,
				Name: `${leg.LegSymbol} - ${formattedLegSide}`,
				Status: strategy.OrdStatus ?? strategy.Status,
				StatusDate: leg.LegDatedDate,
			};

			const isContinousStrategy = strategy.StrategyCode === 'cont';

			if (isContinousStrategy) {
				const continousLeg = getContinousLeg(
					currentLeg,
					'history',
					formattedId
				);

				newLegsCont.push(continousLeg);
			}

			formattedStrategies.push(currentLeg);
		});

		if (newLegsCont.length > 0) {
			formattedStrategies = [...formattedStrategies, ...newLegsCont];
		}
	});

	return formattedStrategies;
}

export function formatDefaultStrategies(strategies, context = '') {
	let formattedStrategies = [];

	strategies.forEach(strategy => {
		formattedStrategies.push({
			...strategy,
			id: strategy.ClOrdID,
			hierarchy: [strategy.ClOrdID],
			strategyCode: strategy.StrategyCode,
			TimeInForce: strategy.TimeInForce ?? '0',
			Status: strategy.OrdStatus ?? strategy.Status,
			CumQtyPerc: strategy.CumQtyPerc ?? 0,
		});

		const newLegsCont = [];

		strategy?.Legs?.forEach(leg => {
			const currentLeg = {
				...leg,
				id: leg.LegRefID,
				hierarchy: [strategy.ClOrdID, leg.LegRefID],
				Text: leg.LegMsg,
				CumQtyPerc: leg.LegCumQtyPerc ?? 0,
				TargetQtty: leg.LegQuantity,
				ExecutedQtty: leg.LegExecQty,
				AvgPrice: leg.LegAvgPx,
				Accounts: leg.ILegAllocAccount,
				Name: `${leg.LegSymbol} - ${legSides[leg.LegSide]}`,
				Status: strategy.OrdStatus ?? strategy.Status,
				StatusDate: leg.LegDatedDate,
				parentStrategy: strategy,
			};

			const isContinousStrategy = strategy.StrategyCode === 'cont';

			if (isContinousStrategy && context == 'getStrategies') {
				const continousLeg = getContinousLeg(
					currentLeg,
					context,
					strategy.ClOrdID
				);

				newLegsCont.push(continousLeg);
			}

			formattedStrategies.push(currentLeg);
		});

		if (newLegsCont.length > 0) {
			formattedStrategies = [...formattedStrategies, ...newLegsCont];
		}
	});

	return formattedStrategies;
}

export function formatRecurrences(recurrences) {
	const formattedRecurrences = [];

	recurrences.forEach(recurrence => {
		formattedRecurrences.push({
			...recurrence,
			id: recurrence.Id,
			hierarchy: [recurrence.Id],
			Name: recurrence.StrategyTemplate.Name,
			StrategyCode: recurrence.StrategyTemplate.StrategyCode,
			Account: recurrence.StrategyTemplate.Account,
		});

		recurrence.ScheduledJobs?.forEach(job => {
			formattedRecurrences.push({
				...job,
				id: job.Id,
				hierarchy: [recurrence.Id, job.Id],
				ActiveStartDate: job.ExecTargetDate,
			});
		});
	});

	return formattedRecurrences;
}
