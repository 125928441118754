import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from '@mui/material';

export const ExternalSignature = ({ open, handleClose }) => {
	return (
		<Dialog open={open} onClose={handleClose}>
			<DialogTitle>Alterar Assinatura Eletrônica</DialogTitle>

			<DialogContent>
				<DialogContentText>
					A troca ou recuperação de sua assinatura eletrônica deve ser
					feita no portal de sua corretora. Na sequência, será
					necessário realizar novamente o login no FlexScan.
				</DialogContentText>
			</DialogContent>

			<DialogActions>
				<Button variant="contained" onClick={handleClose}>
					OK
				</Button>
			</DialogActions>
		</Dialog>
	);
};
