import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

import { Box, colors, Divider, useMediaQuery, useTheme } from '@mui/material';

import { SignOnForm } from './SignOnForm';
import { ContractStep } from './ContractStep';
import { MaterialSnackbar } from 'components/MaterialComponents/MaterialSnackbar';

import { doLogout } from 'store/modules/auth/actions';
import { apiCadastro } from 'services/api';

import elliotLogo from 'assets/images/logo-elliot-dark.png';
import flexScanLogo from 'assets/images/flexscan_logo.png';

const PARTNERS_LOGOS = {
	eli: elliotLogo,
	flx: flexScanLogo,
};

export const SingleSignOn = () => {
	const { ssoToken } = useParams();
	const history = useHistory();
	const dispatch = useDispatch();
	const theme = useTheme();

	const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

	const { token } = useSelector(state => state.auth);

	const [signOnStep, setSignOnStep] = useState('sign-on-form');
	const [snackbarState, setSnackbarState] = useState({
		isOpen: false,
		message: '',
		severity: 'success',
		autoHide: null,
	});
	const [userInfo, setUserInfo] = useState(null);
	const [authToken, setAuthToken] = useState(null);
	const [contractInfo, setContractInfo] = useState(null);
	const [isTokenValid, setIsTokenValid] = useState(true);

	let decodedToken = null;

	try {
		decodedToken = jwtDecode(ssoToken);
	} catch (error) {
		console.error('Token inválido:', error);
	}

	useEffect(() => {
		if (token) {
			dispatch(doLogout(history));
			history.push('/');
			return;
		}

		const body = { service_type: 'page_validation' };
		const header = { headers: { Authorization: `Bearer ${ssoToken}` } };

		apiCadastro
			.post('/credentials/tokens', body, header)
			.then(response => {
				setIsTokenValid(true);
				setUserInfo(response.data);
			})
			.catch(error => {
				console.error(error);
				setIsTokenValid(false);
				setSnackbarState(state => ({
					...state,
					isOpen: true,
					message:
						'Não foi possível prosseguir com a solicitação. Favor realizar o login pelo portal novamente.',
					severity: 'error',
					autoHide: 5000,
				}));
			});
	}, []);

	const logo = PARTNERS_LOGOS[decodedToken?.sponsor ?? 'flx'];

	return (
		<>
			<Box
				sx={{
					bgcolor: colors.grey[200],
					width: '100dvw',
					height: '100dvh',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<Box
					sx={{
						borderRadius: 4,
						display: 'flex',
						justifyContent: isSmallScreen
							? 'center'
							: 'space-evenly',
						alignItems: 'center',
						flexDirection: isSmallScreen ? 'column' : 'row',
						gap: isSmallScreen ? 2 : 6,
						width: isSmallScreen ? '100%' : 1000,
						height: isSmallScreen ? '100%' : 'auto',
						bgcolor: colors.grey[50],
						p: isSmallScreen ? 2 : 6,

						'& >img': {
							width: isSmallScreen ? '50%' : 250,
						},
					}}
				>
					<img src={logo} alt="" />

					{!isSmallScreen && (
						<Divider orientation="vertical" flexItem />
					)}

					<Box sx={{ width: isSmallScreen ? '90%' : 350, mt: 1 }}>
						{signOnStep === 'sign-on-form' && (
							<SignOnForm
								userInfo={userInfo}
								isMobile={isSmallScreen}
								isTokenValid={isTokenValid}
								setAuthToken={setAuthToken}
								setSignOnStep={setSignOnStep}
								setContractInfo={setContractInfo}
								setSnackbarState={setSnackbarState}
							/>
						)}

						{signOnStep === 'contract' && (
							<ContractStep
								pendingContract={contractInfo}
								isOpen={signOnStep === 'contract'}
								onClose={() => setSignOnStep('sign-on-form')}
								authToken={authToken}
								userInfo={userInfo}
								setSnackbarState={setSnackbarState}
							/>
						)}
					</Box>
				</Box>
			</Box>

			<MaterialSnackbar
				open={snackbarState.isOpen}
				severity={snackbarState.severity}
				autoHide={snackbarState.autoHide}
				handleClose={() =>
					setSnackbarState(state => ({ ...state, isOpen: false }))
				}
			>
				{snackbarState.message}
			</MaterialSnackbar>
		</>
	);
};
