import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Contract } from './Modals/Contract';
import { ChangePassword } from './Modals/ChangePassword';
import { ChangeSignature } from './Modals/ChangeSignature';
import { SendToEmail } from './Modals/NoSignature/SendToEmail';
import { NewSignature } from './Modals/NoSignature/NewSignature';
import { UnauthorizedBullet } from './Modals/UnauthorizedBullet';
import { ExternalAccessToken } from './Modals/ExternalAccessToken';
import { VerificationCode } from './Modals/NoSignature/VerificationCode';

import { Header } from './Header';
import { Navbar } from './Navbar';
import { MaterialSnackbar } from 'components/MaterialComponents/MaterialSnackbar';
import { UserProfile } from './Header/HeaderTools/UserProfile';
import { ExternalSignature } from './Modals/ExternalSignature';

const AppHeader = () => {
	const initialState = {
		password: {
			oldPassword: '',
			newPassword: '',
			confirmationPassword: '',
		},
		passwordRegex: {
			caracteres: false,
			numbers: false,
			upperLowCase: false,
			special: false,
			emojiCaracteres: false,
		},
		signature: {
			oldSignature: '',
			newSignature: '',
			confirmationSignature: '',
		},
		signatureRegex: {
			caracteres: false,
			numbers: false,
		},
	};

	const authToken = useSelector(state => state.auth.token);
	const bullet = useSelector(state => state.bottomBullet.bullet);
	const { portalAccess, useBrokerSignature } = useSelector(
		state => state.auth
	);

	const [contractCode, setContractCode] = useState(null);
	const [credentials, setCredentials] = useState(initialState);
	const [openedModal, setOpenedModal] = useState('');
	const [transactionalToken, setTransactionalToken] = useState('');
	const [snackbarState, setSnackbarState] = useState({
		isOpen: false,
		message: '',
		severity: 'error',
	});

	function handleClose() {
		setOpenedModal('');
		setCredentials(initialState);
	}

	function handleOpenContract(productCode) {
		setContractCode(productCode);
		setOpenedModal('contract');
	}

	return (
		<>
			<Header
				openedModal={openedModal}
				handleOpenModal={setOpenedModal}
			/>

			<Navbar />

			<UnauthorizedBullet open={bullet.unauthorized} bullet={bullet} />

			{openedModal === 'profile' && (
				<UserProfile
					isOpen={openedModal === 'profile'}
					handleClose={handleClose}
					handleOpenContract={handleOpenContract}
				/>
			)}

			{openedModal === 'contract' && (
				<Contract
					open={openedModal === 'contract'}
					contractCode={contractCode}
					handleClose={handleClose}
				/>
			)}

			{useBrokerSignature ? (
				<ExternalSignature
					open={openedModal === 'external-signature'}
					handleClose={handleClose}
				/>
			) : (
				<ChangeSignature
					open={openedModal === 'change-signature'}
					authToken={authToken}
					credentials={credentials}
					handleClose={handleClose}
					setCredentials={setCredentials}
				/>
			)}

			{!portalAccess ? (
				<>
					<SendToEmail
						open={openedModal === 'send-email'}
						handleClose={handleClose}
						setOpenedModal={setOpenedModal}
						setTransactionalToken={setTransactionalToken}
						setSnackbarState={setSnackbarState}
					/>

					<VerificationCode
						open={openedModal === 'verification-code'}
						handleClose={handleClose}
						setOpenedModal={setOpenedModal}
						transactionalToken={transactionalToken}
						setTransactionalToken={setTransactionalToken}
						setSnackbarState={setSnackbarState}
					/>

					<NewSignature
						open={openedModal === 'new-signature'}
						credentials={credentials}
						handleClose={handleClose}
						initialState={initialState}
						setCredentials={setCredentials}
						transactionalToken={transactionalToken}
						setSnackbarState={setSnackbarState}
					/>

					<ChangePassword
						open={openedModal === 'change-password'}
						authToken={authToken}
						credentials={credentials}
						handleClose={handleClose}
						setCredentials={setCredentials}
					/>
				</>
			) : (
				<>
					<ExternalAccessToken
						open={openedModal === 'access-token'}
						onClose={handleClose}
					/>
				</>
			)}

			<MaterialSnackbar
				{...snackbarState}
				open={snackbarState.isOpen}
				severity={snackbarState.severity ?? 'error'}
				handleClose={() =>
					setSnackbarState({
						isOpen: false,
						message: '',
					})
				}
			>
				{snackbarState.message}
			</MaterialSnackbar>
		</>
	);
};

export default AppHeader;
