import {
	colors,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
} from '@mui/material';
import {
	calcFinancialStop,
	getFinalRangePrice,
	getFinancialStopColor,
	getGradientRisk,
	getMaxContractExposure,
	getStopLossPrice,
} from 'helpers/gradient/riskEstimates';
import React from 'react';

const RED_COLOR = '#ff5a5a';

export const EstimatesTable = ({
	customParameters,
	strategyInfos,
	strategyLegs,
}) => {
	function getFormattedNumber(number, minDigits = null, maxDigits = null) {
		if (number === 0) {
			return 0;
		}

		if (!number || isNaN(number)) {
			return '-';
		}

		return new Intl.NumberFormat('pt-BR', {
			minimumFractionDigits: minDigits,
			maximumFractionDigits: maxDigits ?? minDigits,
		}).format(number);
	}

	const financialStopLoss = calcFinancialStop(
		customParameters,
		strategyInfos,
		strategyLegs
	);
	const financialStopColor = getFinancialStopColor(
		financialStopLoss,
		strategyInfos,
		customParameters,
		strategyLegs
	);

	const estimates = [
		{
			id: 'financeStopLoss',
			label: 'Stop Loss financeiro (R$)',
			value: (
				<span style={{ color: financialStopColor }}>
					{getFormattedNumber(financialStopLoss, 2)}
				</span>
			),
		},
		{
			id: 'rangeRisk',
			label: 'Risco do range (R$)',
			value: (
				<span style={{ color: RED_COLOR }}>
					{getFormattedNumber(
						getGradientRisk(
							strategyInfos,
							customParameters,
							strategyLegs
						),
						2
					)}
				</span>
			),
		},
		{
			id: 'maximumContractExposure',
			label: 'Exposição máxima de contratos',
			value: getMaxContractExposure(
				customParameters.IncrementPriceStepEntrance,
				customParameters.NumberOfEntrances,
				customParameters.OrderQuantity
			),
		},
		{
			id: 'rangeStartPrice',
			label: 'Preço de início do range',
			value: getFormattedNumber(
				customParameters.EntrancePrice,
				strategyInfos[0].tickSizeDenominator
			),
		},
		{
			id: 'rangeEndPrice',
			label: 'Preço de fim do range',
			value: getFinalRangePrice(
				customParameters,
				strategyInfos,
				strategyLegs
			),
		},
		{
			id: 'stopLossPrice',
			label: 'Preço de stop loss',
			value: (
				<span style={{ color: RED_COLOR }}>
					{getStopLossPrice(
						customParameters,
						strategyInfos,
						strategyLegs
					)}
				</span>
			),
		},
	];

	return (
		<>
			<Table
				size="small"
				sx={{
					bgcolor: colors.grey[900],
					borderRadius: 1,
				}}
			>
				<TableHead>
					<TableRow>
						<TableCell>
							<strong style={{ fontSize: 'small' }}>
								Estimativa de risco inicial
							</strong>
						</TableCell>
						<TableCell></TableCell>
					</TableRow>
				</TableHead>
				<TableRow>
					<TableCell colSpan={2} sx={{ border: 'none !important' }} />
				</TableRow>
				<TableBody>
					{estimates.map(estimate => (
						<TableRow key={estimate.id}>
							<TableCell
								sx={{ border: 0, my: 1, fontSize: 'small' }}
							>
								{estimate.label}
							</TableCell>
							<TableCell
								sx={{
									border: 0,
									textAlign: 'right',
									fontSize: 'small',
								}}
							>
								{estimate.value}
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</>
	);
};
