import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { LoadingButton } from '@mui/lab';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from '@mui/material';

import { apiAWS } from 'services/api';
import { signInAsync } from 'store/modules/auth/actions';

export const ContractStep = ({
	pendingContract,
	isOpen,
	onClose,
	authToken,
	userInfo,
	setSnackbarState,
}) => {
	const history = useHistory();
	const dispatch = useDispatch();

	const environmentType = useSelector(state => state.auth.accountType);

	const [isLoading, setIsLoading] = useState(false);

	function handleSubmit() {
		setIsLoading(true);

		const headers = { headers: { Authorization: `Bearer ${authToken}` } };

		const username = userInfo.user_name.toLowerCase();

		apiAWS
			.post(`/users/${username}/contracts`, pendingContract, headers)
			.then(response => {
				setSnackbarState(state => ({
					...state,
					isOpen: true,
					severity: 'success',
					message: 'Contrato assinado com sucesso.',
				}));
				dispatch(
					signInAsync({
						authToken: authToken,
						userName: userInfo.user_name,
						history,
						accountType: environmentType,
						userIp: response.data.user_ip,
						userDate: response.data.datetime,
						productPath: pendingContract.product,
						handleCancelContractSign: () => onClose(),
					})
				);
			})
			.catch(error => {
				console.error(error);
				setSnackbarState(state => ({
					...state,
					isOpen: true,
					severity: 'error',
					message:
						'Problema durante a assinatura do contrato. Tente novamente mais tarde.',
				}));
			})
			.finally(() => setIsLoading(false));
	}

	return (
		<Dialog
			open={isOpen}
			onClose={onClose}
			fullWidth
			maxWidth="lg"
			sx={{
				'& .MuiDialog-paper': {
					height: '80vh',
					display: 'flex',
					flexDirection: 'column',
				},
			}}
		>
			<DialogTitle variant="h4">Termos de uso</DialogTitle>

			<DialogContent
				sx={{
					flex: 1,
					display: 'flex',
					flexDirection: 'column',
					overflow: 'hidden',
					'& iframe': {
						flex: 1,
						width: '100%',
						height: '100%',
					},
				}}
			>
				<DialogContentText variant="h6" sx={{ mb: 2 }}>
					Para utilizar a plataforma você precisa aceitar os termos de
					uso.
				</DialogContentText>

				<iframe src={pendingContract.file} />
			</DialogContent>

			<DialogActions>
				<Button onClick={onClose} variant="contained" color="error">
					Fechar
				</Button>

				<LoadingButton
					onClick={handleSubmit}
					variant="contained"
					color="primary"
					loading={isLoading}
				>
					Aceitar
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
};
